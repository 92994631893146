import React, { useState } from "react"
import { Text, Box, Grid, Flex, Heading, Container } from "theme-ui"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import ArrowSliderLeft from "../../assets/images/arrow-left.svg"
import ArrowSliderRight from "../../assets/images/arrow-right.svg"
import Embed from "../../components/blocks/embed"
import { useBreakpointIndex } from "@theme-ui/match-media"
SwiperCore.use([Navigation, Mousewheel, A11y])

const VideoSlider = ({ videos }) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box
      sx={{
        position: "relative",
        pt: [4],
        pb: [4],
      }}
    >
      <Grid columns={["1fr", "1fr", "1fr", ".1fr .9fr"]}>
        <Box>
          <Flex
            sx={{
              height: "100%",
              flexDirection: ["row", "row", "row", "column"],
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Heading
                as="div"
                variant="sectionTitle"
                sx={{ h2: { pb: [0, "auto"] } }}
              >
                <Box as="h2">Video</Box>
              </Heading>
            </Box>
            <Flex>
              <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: [2] }}>
                <Box
                  as="div"
                  variant="svg.arrow.dark"
                  sx={{
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <ArrowSliderLeft />
                </Box>
              </Box>
              <Box as="div" ref={node => setNextEl(node)}>
                <Flex
                  variant="svg.arrow.dark"
                  sx={{
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <ArrowSliderRight />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>

        <Box sx={{ ml: [0, 0, 0, 3] }}>
          <Swiper
            slidesPerView={
              breakpointIndex < 3 ? 1 : videos.length > 1 ? 1.5 : 1
            }
            spaceBetween={20}
            paginationClickable={true}
            draggable={true}
            centeredSlides={true}
            slideToClickedSlide={true}
            centeredSlidesBounds={true}
            id={"mySwiper-videos"}
            className={"mySwiper padding-20"}
            navigation={{ prevEl, nextEl }}
          >
            {videos &&
              videos.length > 0 &&
              videos.map(video => {
                return (
                  <SwiperSlide>
                    <Box>
                      <Embed fullWidth code={video.embedString} />
                    </Box>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </Box>
      </Grid>
    </Box>
  )
}

export default VideoSlider
