import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Grid, Heading, Flex, Text } from "@theme-ui/components"
import { ChevronUp, ChevronDown } from "react-feather"
import Layout from "../components/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { useFavicon } from "../hooks/useFavicon"
import { ProductGallery } from "../components/blocks/productGallery"
import { MagicLink } from "../utils/magicLink"
import ContactForm from "../components/blocks/contactForm"
import TechnicalSpecifications from "../components/blocks/technicalSpecifications"
import Accordion from "../components/blocks/accordion"
import * as Scroll from "react-scroll"
import VideoSlider from "../components/blocks/videoSlider"
import RelatedProductsSlider from "../components/blocks/relatedProductsSlider"
import FooterServiceInformation from "../components/blocks/footerServiceInformation"
import { i18nContext } from "../context/i18nContext"
import ArrowRight from "../assets/images/arrow-right.svg"
import ReactReadMoreReadLess from "../components/reactReadMore"
import { getPagePath, getProductPath } from "../utils/path"
import GreenNumber from "../components/greenNumber"
import Breadcrumbs from "../components/breadcrumbs"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"
import { InboundLink } from "../components/link"

const Page = ({
  data: {
    page,
    site,
    parentCategory,
    familyCategories,
    categories,
    footerServiceInformation,
    socafPage,
  },
}) => {
  console.log(page)
  const favicon = useFavicon().site.faviconMetaTags
  const LinkScroll = Scroll.Link

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const checkParentCategory = (parentCategories, category) => {
    if (parentCategories && category)
      return JSON.parse(JSON.stringify(parentCategories)).nodes.filter(
        familyCategory => {
          let foundParent = familyCategory.categories.filter(categories => {
            return (
              JSON.parse(JSON.stringify(categories)).id ===
              JSON.parse(JSON.stringify(category)).id
            )
          })
          if (foundParent.length > 0) return familyCategory
        }
      )[0]
  }

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getProductPath(
        page,
        locale.locale,
        familyCategories,
        parentCategory
      ),
    }
  })

  function clickButton() {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []

      window.dataLayer.push({
        event: "richiedi_preventivo",
        pageURL: window.location.href,
      })
    }
  }

  const primary = getColor(themeUiTheme, "primary")

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: page.title,
    image:
      page.images && page.images.length > 0
        ? page.images.map(image => image.gatsbyImageData.images.fallback.src)
        : null,
    description: page.shortDescription,
    itemCondition: page.condition,
    brand: {
      "@type": "Brand",
      name: "Socaf",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.6",
      reviewCount: "5",
    },
    offers: {
      "@type": "Offer",
      availability: page.availability,
    },
  }

  // console.log(ldJson)

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} color="dark">
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetDatoCms>
      <Box
        sx={{
          position: "relative",
          mb: 0,
          pt: [6, 6, 6, "18rem"],
          pb: [0, 6, 0],
          height: ["100%"],
          zIndex: 0,
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "column", "row", "row"],
          }}
        >
          <Breadcrumbs
            page={page}
            familyCategory={checkParentCategory(
              familyCategories,
              parentCategory.nodes[0]
            )}
            familyCategories={familyCategories}
            productCategory={parentCategory.nodes[0]}
          />
          {page.socafGear && (
            <Box sx={{ flexBasis: "49%" }}>
              <Text
                as="p"
                sx={{
                  mt: [3, 3, 0, 0],
                  width: "fit-content",
                  backgroundColor: "primary",
                  color: "light",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  height: "40px",
                  px: 2,
                  py: "14px",
                  fontWeight: 400,
                  fontSize: [1, 1, 1, 2],
                  alignItems: "center",
                  border: "1px solid",
                  borderRadius: "20px",
                  borderColor: "primary",
                }}
              >
                by Socaf
              </Text>
            </Box>
          )}
        </Container>
      </Box>
      <Box>
        <Container>
          <Grid columns={[1, 2]} gap={[2, 6]} sx={{ pt: [0] }}>
            <Box>
              <ProductGallery pageId={page.id} images={page.images} />
            </Box>
            <Box>
              <Box sx={{ mb: [4] }}>
                <Heading sx={{ p: { m: 0 } }} as="h1" variant="h1">
                  {page.title}
                </Heading>
                <ReactReadMoreReadLess
                  charLimit={900}
                  readMoreText={
                    <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                      <Flex
                        sx={{
                          alignItems: "center",
                          alignContent: "center",
                          color: "primary",
                        }}
                        mr={[1]}
                      >
                        Leggi di più
                      </Flex>
                      <ChevronDown size={24} color={primary} />
                    </Flex>
                  }
                  readLessText={
                    <Flex sx={{ alignItems: "center", alignContent: "center" }}>
                      <Flex
                        sx={{
                          alignItems: "center",
                          alignContent: "center",
                          color: "primary",
                        }}
                        mr={[1]}
                      >
                        Leggi di meno
                      </Flex>
                      <ChevronUp size={24} color={primary} />
                    </Flex>
                  }
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  {page.description}
                </ReactReadMoreReadLess>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "inline-block",
                    pt: [2],
                    width: ["100%", "100%", "auto", "auto", "auto"],
                  }}
                >
                  <Flex
                    sx={{
                      gap: 2,
                      flexDirection: ["column", "column", "row"],
                      a: {
                        textDecoration: "none",
                      },
                    }}
                  >
                    <LinkScroll
                      to="contactForm"
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => clickButton()}
                    >
                      <Flex
                        sx={{
                          height: "100%",
                          px: [4],
                          py: [3],
                          fontSize: [2],
                          fontWeight: "bold",
                          textDecoration: "none",
                          cursor: "pointer",
                          color: "light",
                          "-webkit-transition": "background-color .2s linear",
                          "-ms-transition": "background-color .2s linear",
                          transition: "background-color .2s linear",
                          backgroundColor: "primary",
                          "&:hover": {
                            backgroundColor: "dark",
                            color: "light",
                            svg: {
                              circle: {
                                stroke: "light",
                                strokeWidth: "2px",
                              },
                              use: {
                                fill: "light",
                              },
                              "g#Group": {
                                fill: "light",
                              },
                            },
                          },
                          alignItems: "center",
                          justifyContent: "space-between",
                          textDecoration: "none",
                        }}
                      >
                        <Box>Richiedi preventivo</Box>
                        <Flex
                          variant="svg.arrow.light"
                          sx={{
                            alignItems: "center",
                            alignContent: "center",
                            width: "24px",
                            height: "24px",
                            ml: [4],
                          }}
                        >
                          <ArrowRight />
                        </Flex>
                      </Flex>
                    </LinkScroll>
                    {page.socafGear && (
                      <InboundLink
                        to={getPagePath(socafPage, socafPage.locale)}
                      >
                        <Flex
                          sx={{
                            px: [4],
                            py: [3],
                            fontSize: [1],
                            fontWeight: "bold",
                            textDecoration: "none",
                            border: "2px solid",
                            borderColor: "primary",
                            cursor: "pointer",
                            color: "primary",
                            "-webkit-transition": "background-color .2s linear",
                            "-ms-transition": "background-color .2s linear",
                            transition: "background-color .2s linear",
                            backgroundColor: "light",
                            "&:hover": {
                              backgroundColor: "primary",
                              color: "light",
                              svg: {
                                circle: {
                                  stroke: "light",
                                  strokeWidth: "2px",
                                },
                                use: {
                                  fill: "light",
                                },
                                "g#Group": {
                                  fill: "light",
                                },
                              },
                            },
                            alignItems: "center",
                            justifyContent: "space-between",
                            textDecoration: "none",
                          }}
                        >
                          <Box>Scopri la gamma Socaf</Box>
                          <Flex
                            variant="svg.arrow.primary"
                            sx={{
                              alignItems: "center",
                              alignContent: "center",
                              width: "24px",
                              height: "24px",
                              ml: [4],
                            }}
                          >
                            <ArrowRight />
                          </Flex>
                        </Flex>
                      </InboundLink>
                    )}
                  </Flex>
                  <Box
                    sx={{
                      mt: [3],
                      display: ["block", "block", "none", "none", "none"],
                    }}
                  >
                    <GreenNumber />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mt: 4 }}>
                <Heading
                  as="h2"
                  variant="h5"
                  sx={{ fontWeight: "bold", mt: 0, mb: 3 }}
                >
                  Caratteristiche principali
                </Heading>

                {page.powerSupply.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "primary",
                      my: [2],
                      p: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontWeight: "600" }}>Alimentazione</Text>
                    </Box>
                    <Box>
                      <Box as="ul" sx={{ my: [2, 2, 2, 2] }}>
                        {page.powerSupply.map(dirt => (
                          <Box as="li">
                            <Text>{dirt.title}</Text>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
                {page.hoursOfUse.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "primary",
                      my: [2],
                      p: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontWeight: "600" }}>Ore di utilizzo</Text>
                    </Box>
                    <Box>
                      <Box as="ul" sx={{ my: [2, 2, 2, 2] }}>
                        {page.hoursOfUse.map(dirt => (
                          <Box as="li">
                            <Text>{dirt.title}</Text>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}
                {(page.fromFloorArea || page.toFloorArea) && (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "primary",
                      my: [2],
                      p: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontWeight: "600" }}>Metratura</Text>
                    </Box>
                    <Box sx={{ my: [2, 2, 2, 2] }}>
                      {page.fromFloorArea ? (
                        <>
                          <Text>
                            da {page.fromFloorArea}
                            {page.fromFloorArea && !page.toFloorArea && " m²"}
                          </Text>
                          {page.toFloorArea && (
                            <Text> a {page.toFloorArea} m²</Text>
                          )}
                        </>
                      ) : (
                        !page.fromFloorArea &&
                        page.toFloorArea && (
                          <Text> fino a {page.toFloorArea} m²</Text>
                        )
                      )}

                      <Text>{page.floorArea}</Text>
                    </Box>
                  </Box>
                )}
                {page.recommendedUse && (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "primary",
                      my: [2],
                      p: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontWeight: "600" }}>
                        Impiego consigliato
                      </Text>
                    </Box>
                    <Box sx={{ my: [2, 2, 2, 2] }}>
                      <Text>{page.recommendedUse}</Text>
                    </Box>
                  </Box>
                )}
                {page.typeOfDirt.length > 0 && (
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "primary",
                      my: [2],
                      p: [3],
                    }}
                  >
                    <Box>
                      <Text sx={{ fontWeight: "600" }}>Tipo di sporco</Text>
                    </Box>
                    <Box>
                      <Box as="ul" sx={{ my: [2, 2, 2, 2] }}>
                        {page.typeOfDirt.map(dirt => (
                          <Box as="li">
                            <Text>{dirt.title}</Text>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                )}

                {page.brochure && (
                  <Box
                    sx={{
                      my: 2,
                      p: [3],
                      px: [0],
                    }}
                  >
                    {page.brochure.documents.map(document => (
                      <MagicLink
                        target="_blank"
                        href={document.url}
                        sx={{ position: "relative", display: "block" }}
                      >
                        Scarica la brochure
                      </MagicLink>
                    ))}
                  </Box>
                )}
                {page.technicalSpecificationDocument && (
                  <Box
                    sx={{
                      my: 2,
                      p: [3],
                      px: [0],
                    }}
                  >
                    {page.technicalSpecificationDocument.documents.map(
                      document => (
                        <MagicLink
                          target="_blank"
                          href={document.url}
                          sx={{ position: "relative", display: "block" }}
                        >
                          Scarica la scheda tecnica
                        </MagicLink>
                      )
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          {page.enhancedDescription.length > 0 && (
            <Box sx={{ my: [6, 8] }}>
              <Box sx={{ mb: [4] }}>
                <Text>{page.enhancedDescription[0].kicker}</Text>
              </Box>
              <Grid
                columns={[1, 1, "1.5fr 3fr"]}
                gap={["32px", "32px", "128px"]}
              >
                <Box>
                  <Heading as="div" variant="sectionTitle">
                    <Box as="h2">{page.enhancedDescription[0].title}</Box>
                  </Heading>
                  <Box
                    sx={{ fontSize: [2], lineHeight: [1.3], p: { m: 0 } }}
                    dangerouslySetInnerHTML={{
                      __html: page.enhancedDescription[0].body,
                    }}
                  />
                </Box>
                {page.enhancedDescription[0].content.map(block => (
                  <Accordion title={block.title} items={block.content} />
                ))}
              </Grid>
            </Box>
          )}
          {page.secondaryDescription && (
            <Box
              sx={{
                mt: [3, 5, 6],
                borderTop: "1px solid #e6e6e6",
                h2: { mt: 5 },
              }}
              dangerouslySetInnerHTML={{ __html: page.secondaryDescription }}
            />
          )}
          {page.videos && page.videos.length > 0 && (
            <Box sx={{ my: [6, 8] }}>
              <VideoSlider videos={page.videos} />
            </Box>
          )}
          {page.technicalSpecifications && (
            <Box sx={{ my: [6, 8] }}>
              <Box>
                <Heading as="div" variant="sectionTitle">
                  <Box as="h2">Caratteristiche tecniche</Box>
                </Heading>
              </Box>
              <TechnicalSpecifications data={page.technicalSpecifications} />
            </Box>
          )}
        </Container>
        {page.relatedProducts && page.relatedProducts.length > 0 && (
          <Box sx={{ backgroundColor: "Grey" }}>
            <Container variant="fw">
              <Grid
                sx={{ px: [3, 0] }}
                columns={[1, 1, 1, "calc(50vw - 640px) 1fr"]}
              >
                <Box></Box>
                <RelatedProductsSlider
                  relatedProducts={page.relatedProducts}
                  familyCategories={familyCategories}
                  parentCategory={categories}
                />
              </Grid>
            </Container>
          </Box>
        )}
      </Box>
      <Box>
        <i18nContext.Consumer>
          {t => (
            <Container>
              <ContactForm
                title={t.form.title}
                subtitle={t.form.subtitle}
                description={t.form.newsletterDescription}
              />
            </Container>
          )}
        </i18nContext.Consumer>
      </Box>
      <Box>
        <FooterServiceInformation services={footerServiceInformation.service} />
      </Box>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ProductQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    socafPage: datoCmsPage(
      id: { eq: "DatoCmsPage-GXX_OLu3QDmSnvIqLHd2CQ-it" }
    ) {
      root
      slug
      locale
      _allSlugLocales {
        locale
        value
      }
    }
    page: datoCmsProduct(id: { eq: $id }) {
      id
      ...AllProductSlugLocales
      title
      socafGear
      shortTitle
      shortDescription
      description
      secondaryDescription
      availability
      condition
      eco
      enhancedDescription {
        body
        kicker
        title
        model {
          apiKey
        }
        content {
          title
          content {
            title
            image {
              url
            }
            body {
              value
            }
          }
        }
      }
      technicalSpecificationDocument {
        documents {
          url
          title
          format
        }
      }
      brochure {
        documents {
          url
          title
          format
        }
      }

      technicalSpecifications
      videos {
        embedString
      }
      powerSupply {
        id
        ... on DatoCmsProductVariantPowerSupply {
          title
        }
      }
      hoursOfUse {
        id
        ... on DatoCmsProductVariantHoursOfUse {
          title
        }
      }
      typeOfDirt {
        id
        ... on DatoCmsProductVariantTypeOfDirt {
          title
        }
      }
      fromFloorArea
      toFloorArea
      recommendedUse
      relatedProducts {
        id
        thumbnailImage {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
        images {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
        slug
        locale
        shortTitle
        shortDescription
      }
      content {
        ... on DatoCmsContactForm {
          id
          kicker
          subtitle
          title
          newsletterDescription
          model {
            apiKey
          }
        }
      }

      slug
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      images {
        gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
      }
      model {
        apiKey
      }
    }

    parentCategory: allDatoCmsProductCategory(
      filter: {
        products: { elemMatch: { id: { in: [$id] } } }
        slug: { ne: null }
        locale: { eq: $locale }
      }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        ...ProductCategoryPageDetails
        model {
          apiKey
        }
      }
    }

    categories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        slug
        products {
          id
          title
          slug
        }
        model {
          apiKey
        }
      }
    }

    familyCategories: allDatoCmsProductFamily(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        slug
        categories {
          slug
          id
          title
          products {
            id
            title
            slug
          }
        }
        model {
          apiKey
        }
      }
    }

    products: allDatoCmsProduct(
      filter: { locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        slug
        locale
        ...AllProductSlugLocales
        title
      }
    }

    footerServiceInformation: datoCmsServiceInformation {
      id
      service {
        ... on DatoCmsRichContent {
          ...RichContent
        }
      }
    }
  }

  fragment ProductPageDetails on DatoCmsProduct {
    id
    title
    shortTitle
    slug
    description
    shortDescription
    images {
      gatsbyImageData(width: 800, placeholder: NONE, forceBlurhash: false)
    }
    thumbnailImage {
      gatsbyImageData(width: 800, placeholder: NONE, forceBlurhash: false)
    }
    locale
    model {
      apiKey
    }
  }

  fragment AllProductSlugLocales on DatoCmsProduct {
    _allSlugLocales {
      value
      locale
    }
  }
`
