import React from "react"
import { Text, Box } from "theme-ui"

const TechnicalSpecifications = ({ data }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Text
        as="div"
        variant="p"
        sx={{
          color: "dark",
          pb: [4],
          overflow: "auto",
          p: { m: 0, letterSpacing: "2px" },
          width: "100%",
          maxWidth: "90vw",
          p: {
            m: 0,
            fontWeight: "600",
            letterSpacing: "2px",
          },
          table: {
            th: {
              p: [3],
              textAlign: "left",
            },
            width: "100%",
            borderCollapse: "collapse",
            "tbody tr": {
              borderColor: "light",
              borderBottom: "1px solid",
              td: {
                p: {
                  fontWeight: "600",
                },
                p: [3],
                "&:not(:nth-child(1)) p": {
                  fontWeight: "400",
                },
              },
            },
          },
        }}
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      />
    </Box>
  )
}

export default TechnicalSpecifications
