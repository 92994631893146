import React, { useState } from "react"
import { Text, Box, Grid, Flex, Heading } from "theme-ui"
import ProductThumb from "../productThumb"
import SwiperCore, { Navigation, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ArrowSliderLeft from "../../assets/images/arrow-left.svg"
import ArrowSliderRight from "../../assets/images/arrow-right.svg"
SwiperCore.use([Navigation, Mousewheel, A11y])

const RelatedProductsSlider = ({
  relatedProducts,
  familyCategories,
  parentCategory,
}) => {
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <Box sx={{ position: "relative" }}>
      <Grid columns={["1fr", "1fr", "1fr", ".1fr .9fr"]}>
        <Box>
          <Flex
            sx={{
              height: "100%",
              flexDirection: ["row", "row", "row", "column"],
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Heading as="div" variant="sectionTitle">
                <Box as="h2">Prodotti correlati</Box>
              </Heading>
            </Box>
            <Flex>
              <Box as="div" ref={node => setPrevEl(node)} sx={{ mr: [2] }}>
                <Box
                  as="div"
                  variant="svg.arrow.dark"
                  sx={{
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <ArrowSliderLeft />
                </Box>
              </Box>
              <Box as="div" ref={node => setNextEl(node)}>
                <Flex
                  variant="svg.arrow.dark"
                  sx={{
                    cursor: "pointer",
                    alignItems: "center",
                    alignContent: "center",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <ArrowSliderRight />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Box sx={{ ml: [0, 0, 0, 6] }}>
          <Swiper
            spaceBetween={10}
            slidesPerView={relatedProducts.length < 3 ? 1 : 2.8}
            id={"mySwiper-relatedproducts"}
            className={"mySwiper mySwiper-relatedproducts"}
            navigation={{ prevEl, nextEl }}
            initialSlide={0}
            slidesPerGroup={1}
          >
            {relatedProducts &&
              relatedProducts.length > 0 &&
              relatedProducts.map(product => {
                return (
                  <SwiperSlide>
                    <ProductThumb
                      product={product}
                      parentFamily={familyCategories}
                      parentCategory={parentCategory}
                    />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </Box>
      </Grid>
    </Box>
  )
}

export default RelatedProductsSlider
