import React from "react"
import { Box, Flex, Grid, Heading, Text, Image } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { InboundLink, OutboundLink } from "../link"
import { getServicePath } from "../../utils/path"

const FooterServiceInformation = ({ services }) => {
  return (
    <Box
      sx={{
        position: "relative",
        m: [4],
        mx: [3, 4],
        backgroundColor: "secondary",
      }}
    >
      <Grid columns={[1, 2, 4]} gap={[0]}>
        {services.map(service => (
          <InboundLink
            to={getServicePath(service.link.link, service.link.link.locale)}
            sx={{
              textDecoration: "none",
              "&:hover": {
                ".wrapp-service": {
                  backgroundColor: "dark",
                },
              },
            }}
          >
            <Box
              className="wrapp-service"
              sx={{
                p: [3, 4],
                height: "100%",
                backgroundColor: "transparent",
                "-webkit-transition": "background-color .2s linear",
                "-ms-transition": "background-color .2s linear",
                transition: "background-color .2s linear",
              }}
            >
              <Grid
                sx={{
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "light",
                    mask: `url(${service.image.url}) no-repeat`,
                    "-webkit-mask": `url(${service.image.url}) no-repeat`,
                    "mask-size": "contain",
                    "-webkit-mask-size": "contain",
                    width: "55px",
                    height: "55px",
                  }}
                ></Box>
                <Box sx={{ pb: [0], minHeight: "40px" }}>
                  <Heading
                    as="h4"
                    variant="h4"
                    color="light"
                    sx={{ mt: [2, 2, 2, 2] }}
                  >
                    {service.title}
                  </Heading>
                </Box>
                <Box sx={{ p: { color: "light", pr: [2, 3, 4, 4] } }}>
                  <RichContentStructuredText text={service.body} />
                </Box>
              </Grid>
            </Box>
          </InboundLink>
        ))}
      </Grid>
    </Box>
  )
}

export default FooterServiceInformation
