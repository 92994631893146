import React, { useState } from "react"
import { motion } from "framer-motion"
import { Box, Grid, Link, Flex, Text } from "theme-ui"
import { Swiper, SwiperSlide } from "swiper/react"
import { Thumbs } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"

export function ProductGallery({ pageId, images }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <>
      <Box>
        <Swiper
          spaceBetween={10}
          navigation={true}
          modules={[Thumbs]}
          thumbs={{ swiper: thumbsSwiper }}
          id={"mySwiper2" + pageId}
          className={"mySwiper2 mySwiper2" + pageId}
        >
          {images && images.length > 0 ? (
            images.map(image => {
              return (
                <SwiperSlide>
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",

                      ".gatsby-image-wrapper": {
                        width: "100%",
                        height: ["400px", "500px"],
                        maxHeight: ["400px", "500px"],
                        img: {
                          width: "100%",
                          height: "100%",
                          objectFit: "contain!important",
                        },
                      },
                    }}
                  >
                    <GatsbyImage alt="" image={image.gatsbyImageData} />
                  </Box>
                </SwiperSlide>
              )
            })
          ) : (
            <SwiperSlide>
              <Box
                sx={{
                  height: "100%",
                  maxHeight: "600px",
                  minHeight: "400px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  backgroundColor: "Grey",
                  width: "100%",
                  ".gatsby-image-wrapper": {
                    width: "100%",
                    height: "auto",
                  },
                }}
              ></Box>
            </SwiperSlide>
          )}
        </Swiper>
      </Box>
      <Box
        sx={{
          mt: 3,
          py: [3, 3],
          pl: [0, 0, 0, 0],
          ".swiper-slide > div": {
            border: "1px solid",
            borderColor: "lightGray",
          },
          ".swiper-slide-thumb-active > div": {
            border: "2px solid",
            borderColor: "dark",
          },
        }}
      >
        <Swiper
          onSwiper={swiper => {
            return setThumbsSwiper(swiper)
          }}
          centeredSlides={true}
          slideToClickedSlide={true}
          centeredSlidesBounds={true}
          spaceBetween={32}
          breakpoints={{
            320: {
              slidesPerView: 3.5,
            },
            640: {
              slidesPerView: 4.5,
            },
            896: {
              slidesPerView: 5.5,
            },
          }}
          watchSlidesProgress={true}
          id={"mySwiper" + pageId}
          className={"mySwiper mySwiper" + pageId}
        >
          {images &&
            images.length > 1 &&
            images.map(image => {
              return (
                <SwiperSlide>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      ".gatsby-image-wrapper": {
                        width: "100%",
                        height: "100%",
                        img: {
                          width: "100%",
                          height: "100%",
                        },
                      },
                    }}
                  >
                    <GatsbyImage alt="" image={image.gatsbyImageData} />
                  </Box>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </Box>
    </>
  )
}
